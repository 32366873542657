function isValidOpenValue(value) {
    let goodRange;
    if (value != null && !isNaN(value)) {
        goodRange = value >= 0 && value <= 1000000000 ? true : false;
        return goodRange;
    }
    return false;
}

function isValidTransferValue(value) {
    let goodRange;
    if (value != null && !isNaN(value)) {
        goodRange = value >= -1000000000 && value <= 1000000000 ? true : false;
        return goodRange;
    }
    return false;
}

async function getCasinoList(context, casinoId = null) {
    try {
        let requestUrl = new URL(`/api/v1/site/${casinoId || ""}`, context.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: 'GET',
            headers: headerObj,
        });
        const response = await fetch(request);
        let dataObj = await response.json();

        return dataObj;
    } catch (e) {
        console.error(e);
    }
}

function checkFetchErrors(fetchResponse) {
    let status = {
        code: fetchResponse?.status?.code ? fetchResponse.status.code : fetchResponse.status,
        message: "",
        ok: true,
        userMustDismiss: false,
        created: new Date().getTime(),
        createdTimeString: "",
        createdDateTimeString: "",
        clientSessonTimeout: false,
        forceLogout: false
    };

    if ((!fetchResponse.length > 0 && !fetchResponse.ok) || fetchResponse.status >= 400) {
        status.ok = false;
        switch (fetchResponse.status) {
            case 400:
                status.message = "Bad Request";
                break;
            case 401:
                status.message = "Username or Password is incorrect.";
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 403:
                status.message = "You don't have permissions to do that at this Casino";
                status.userMustDismiss = true;
                break;
            case 409:
                status.message = "Your account has been accessed from another device or tab. Please log in again.";
                status.userMustDismiss = true;
                status.forceLogout = true;
                break;
            case 500:
                status.message = "Internal Server Error";
                break;
            case 503:
                status.message = "Service Unavailable";
                break;
            case undefined:
                status.code = null;
                status.message = "No results";
                break;
            default:
                status.message = fetchResponse.status;
                status.userMustDismiss = true;
        }
        console.error(status);
    }
    return status;
}

function checkSuccessErrors(serverStatus) {
    // errors that are a server 200 but still are an error state for this application.
    // setting code to null even though it was 200 so that 200 doesn't show in the status block of the site header
    let status = {
        code: null,
        message: null,
        ok: true,
        userMustDismiss: false,
        created: new Date().getTime(),
        createdTimeString: "",
        createdDateTimeString: "",
        clientSessonTimeout: false,
        forceLogout: false
    };

    let statusList = [
        "Success",
        "InvalidUser",
        "MiscellaneousError",
        "PasswordTooShort",
        "PasswordTooFewCharacterClasses",
        "PasswordNotAllowed",
        "PasswordIncorrect",
        "DisplayNameTooShort",
        "PhoneNumberInvalidCharacters",
        "PhoneNumberAlreadyInUse",
        "UnableToSendCode",
        "InvalidCode",
        "FailedToSend",
    ];

    status.ok = !statusList.includes(serverStatus) ? true : false;

    switch (serverStatus) {
        case statusList[0]:
            // "Success"
            status.ok = true;
            status.message = "Success";
            break;
        case statusList[1]:
            // "InvalidUser"
            status.message = "Invalid User.";
            break;
        case statusList[2]:
            // "MiscellaneousError"
            status.message = "An unkown error has occurred. Please try again.";
            break;
        case statusList[3]:
            // "PasswordTooShort"
            status.message = "Password Too Short. Must be at least 3 characters.";
            break;
        case statusList[4]:
            // "PasswordTooFewCharacterClasses"
            status.message = "Password Too Few Character Classes";
            break;
        case statusList[5]:
            // "PasswordNotAllowed"
            status.message = "Password Not Allowed";
            break;
        case statusList[6]:
            // "PasswordIncorrect"
            status.message = "Current Password is incorrect.";
            break;
        case statusList[7]:
            // "DisplayNameTooShort"
            status.message = "Display Name too short";
            break;
        case statusList[8]:
            // "PhoneNumberInvalidCharacters"
            status.message = "Phone Number Invalid Characters";
            break;
        case statusList[9]:
            // "PhoneNumberAlreadyInUse"
            status.message = "Phone Number already in use";
            break;
        case statusList[10]:
            // "UnableToSendCode"
            status.message = "Unable To Send Code";
            break;
        case statusList[11]:
            // "InvalidCode"
            status.message = "Invalid Confirmation Code";
            break;
        case statusList[12]:
            // "FailedToSend"
            status.message = "The server is unable to send an SMS to the user's phone number";
            break;
        default:
            status.message = "Unknown Status";
            status.ok = false;
    };

    return status;
}

async function checkAndRefreshSession(vueInstance, state) {
    let status = Object.assign({}, vueInstance.globalStatus);
    let responseJSON = {};
    let min = 60000; // for testing purposes: 1 min
    let hourMS = 3600000;

    // set two hour client session time out: 2 * hourMS
    if (state.accessToken && new Date().getTime() > state.loggedOnTimeCode + 2 * hourMS) {
        status.message = "User session has timed out"
        status.ok = false;
        status.clientSessonTimeout = true;
        status.forceLogout = true;
        return status;
    }

    // Comparing old/new access tokens with 30 seconds wiggle room for potential server latency.
    if (state.accessToken && new Date().getTime() > new Date(state.accessTokenExpiration).getTime() - 30000) {
        let body = {
            oldAccessToken: state.accessToken,
            refreshToken: state.refreshToken,
        };

        let requestUrl = new URL("/api/v1/authentication/refresh", vueInstance.rabbitsfootHostUrl);
        let headerObj = new Headers();
        headerObj.append("Content-Type", "application/json; charset=utf-8");
        let request = new Request(requestUrl.toString(), {
            method: "POST",
            body: JSON.stringify(body),
            headers: headerObj,
        });

        try {
            const response = await fetch(request);

            let fetchStatus = checkFetchErrors(response);

            if (!fetchStatus.ok || response === "Bad OldAccessToken") {
                console.error(response);
                return fetchStatus || response;
            }

            responseJSON = await response.json();

            state.accessTokenExpiration = responseJSON.accessTokenExpiration;
            state.accessToken = responseJSON.accessToken;
            state.refreshToken = responseJSON.refreshToken;

            if (responseJSON.accessToken) fetchStatus.message = "Session refreshed by server";
            return state;
        } catch (e) {
            status.message(`checkAndRefreshSession returned: ${e}`);
            status.ok = false;
            return status;
        }
    } else if (state.accessToken) {
        status.message = "Session from cache";
    }
    return status;
}

async function saveSimpleConfig(context) {
    let requestUrl = new URL("/api/v1/admin/game/simpleconfig", context.rabbitsfootHostUrl);
    let headerObj = new Headers();
    headerObj.append("Authorization", `Bearer ${context.reporterState.accessToken}`);
    headerObj.append("Content-Type", "application/json; charset=utf-8");
    let request = new Request(requestUrl.toString(), {
        method: "POST",
        body: JSON.stringify(context.simpleConfigSaveData),
        headers: headerObj,
    });

    try {
        let response = await fetch(request);

        let fetchStatus = checkFetchErrors(response);

        if (fetchStatus && !fetchStatus.ok) {
            return fetchStatus;
        }

        return fetchStatus;
    } catch (e) {
        console.error(e);
    }
}

export default {
    getCasinoList,
    checkFetchErrors,
    isValidOpenValue,
    isValidTransferValue,
    checkSuccessErrors,
    checkAndRefreshSession,
    saveSimpleConfig
};